import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { URLS, axiosGetWithToken, axiosPostWithToken, axiosPutWithToken } from '../../utils';

import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react';




function AdminApprenants() {
    const [listData, setListData] = useState([]); // Pour stocker les données de la BDD.
    const [currentPage, setCurrentPage] = useState(1); // Pour suivre la page actuelle
    const [searchTerm, setSearchTerm] = useState(''); // Pour stocker le terme de recherche

    const pageSize = 10; // Nombre d'éléments par page
    const [selectedBrand, setSelectedBrand] = useState('');

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };
    const loadData = async () => {
        try {
            const response = await axiosGetWithToken(URLS?.participants);
            console.log("Réponse du serveur : ", response);
            setListData(response?.participants);
        } catch (error) {
            console.error("Erreur lors du chargement des données :", error);
        }
    }



    useEffect(() => {
        loadData();
    }, []);

    // Pagination
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;


    const filterFields = ["nom", "prenom"];

    const currentItems = listData
        .filter(item => {
            return filterFields.some(field =>
                item[field]?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
        .slice(indexOfFirstItem, indexOfLastItem);
    // Change de page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const [openModalAlert, setOpenModalAlert] = useState(false);
    const [openModaFile, setOpenModalFile] = useState(false);

    const [infosMessage, setInfosMessage] = useState('Infos');
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({

    });
    function onCloseModal() {
        setOpenModal(false);
        setFormData({});
    }



    const submiData = async () => {
        try {
            let response;
            if (formData?.id) {
                console.log(formData);
                response = await axiosPutWithToken(`${URLS?.participants}/${formData?.id}`, formData);
            } else {

                response = await axiosPostWithToken(URLS?.participants, formData);

            }
            setInfosMessage(response?.message || "Le participant a été enregistré avec succès.");

        } catch (error) {
            console.error("Erreur lors de la soumission des données :", error);
            setInfosMessage(error?.response?.data?.message || "Erreur lors de la soumission des données");
        } finally {
            console.log(formData);
            setOpenModal(false);
            setOpenModalAlert(true);
            loadData(); // Charger les données uniquement après soumission
            setFormData({});
        }
    };




    const submiDataImport = async () => {
        try {

            let response = await axiosPostWithToken(URLS?.import, formData);
            setInfosMessage(response?.message || "Les participants ont été enregistrés avec succès.");
            console.log(response);

        } catch (error) {
            console.error("Erreur lors de la soumission des données :", error);
            setInfosMessage(error?.response?.data?.message || "Erreur lors de la soumission des données");
        } finally {
            setOpenModalFile(false);
            setOpenModalAlert(true);
            loadData(); // Charger les données uniquement après soumission
            setFormData({});
        }
    };




    return (
        <section className="dark:bg-gray-900 py-3 sm:py-5">
            {/* Le reste du contenu */}


            <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">

                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <form className="flex items-center">
                            <label for="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <input type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button onClick={() => setOpenModal(true)} type="button" className="flex items-center justify-center text-white bg-blue-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                            <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                            </svg>
                            Nouveau apprenant
                        </button>
                        <div className="flex items-center space-x-3 w-full md:w-auto">


                            <div >
                                <select
                                    id="filterDropdown"
                                    className="z-10 block w-48 p-2 bg-white rounded-lg shadow dark:bg-gray-700"
                                    value={selectedBrand}
                                    onChange={handleBrandChange}
                                >
                                    <option value="">Choose brand</option>
                                    <option value="apple">Apple (56)</option>
                                    <option value="fitbit">Microsoft (16)</option>
                                    <option value="razor">Razor (49)</option>
                                    <option value="nikon">Nikon (12)</option>
                                    <option value="benq">BenQ (74)</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">
                {/* Contenu de la table */}
                <div className="flex bg-blue-500 text-white flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                    <div className="flex items-center flex-1 space-x-4">
                        <h5>
                            <span >Total :</span>
                            <span className="dark:text-white">{listData?.length}</span>
                        </h5>

                    </div>
                    <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">

                        <button

                            onClick={() => { setOpenModalFile(true) }}
                            type="button" className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <svg className="h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12" />
                            </svg>

                            Import de la liste
                        </button>
                        <button type="button" className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <svg class="h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            Export
                        </button>
                    </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-blue-500 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all" type="checkbox" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="checkbox-all" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-4 py-3">Nom</th>
                            <th scope="col" className="px-4 py-3">Prénom</th>
                            <th scope="col" className="px-4 py-3">Contact</th>
                            <th scope="col" className="px-4 py-3">Statut</th>
                            <th scope="col" className="px-4 py-3">Autres</th>
                            <th scope="col" className="px-4 py-3">Formation</th>
                            <th scope="col" className="px-4 py-3">Nombre de présence</th>
                            <th scope="col" className="px-4 py-3">Dernier scan</th>
                            <th scope="col" className="px-4 py-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index} className="border-b dark:border-gray-600 hover:bg-blue-200 dark:hover:bg-gray-700">
                                <td className="w-4 px-4 py-3">
                                    <div className="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" onclick="event.stopPropagation()" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label className='mx-2' >{index + 1}</label>

                                    </div>
                                </td>
                                <th scope="row" className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                                    {item?.nom ?? '--'}
                                </th>
                                <td className="px-4 py-2">
                                    <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300"> {item?.prenom ?? "--"}</span>
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap dark:text-white">
                                    Num   : {item?.telephone ?? "--"} <br />
                                    Email : {item?.email ?? "--"}


                                </td>
                                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                                    <div className="flex items-center">
                                        <div className={`inline-block w-4 h-4 mr-2 rounded-full ${item?.statut === "INACTIF" ? 'bg-red-700' : 'bg-green-700'}`}></div>
                                    </div>

                                </td>
                                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item?.age ?? '--'} <br/> {item?.sexe ?? '--'}</td>
                                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                                    <span className="ml-1 text-gray-500 dark:text-gray-400">{item?.formation ?? '--'}</span >
                                </td>
                                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <div className="flex items-center">

                                        {item?.presence ?? '--'}
                                    </div>
                                </td>
                                <td className="px-4 py-2">{item?.last ?? '--'}</td>
                                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                                    <button type="button"
                                        onClick={() => {
                                            setOpenModal(true);
                                            setFormData(item)
                                        }}

                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Edit</button>


                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* Pagination */}
                <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Navigation de la table">
                    {/* Affichage de la pagination */}
                    <ul className="inline-flex items-stretch -space-x-px">
                        {/* Bouton précédent */}
                        <li>
                            <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span className="sr-only">Précédent</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    {/* Icône du bouton précédent */}
                                </svg>
                            </a>
                        </li>
                        {/* Affichage des pages */}
                        {[...Array(Math.ceil(listData.length / pageSize)).keys()].map(number => (
                            <li key={number}>
                                <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => paginate(number + 1)}>
                                    {number + 1}
                                </a>
                            </li>
                        ))}
                        {/* Bouton suivant */}
                        <li>
                            <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span className="sr-only">Suivant</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    {/* Icône du bouton suivant */}
                                </svg>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>


            <Modal show={openModal} size="md" onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="nom" value="Nom" />
                            </div>
                            <TextInput
                                id="nom"
                                placeholder="COMPAORE"
                                value={formData?.nom}
                                onChange={(event) => setFormData({ ...formData, nom: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="prenom" value="Prénom" />
                            </div>
                            <TextInput
                                id="prenom"
                                placeholder="Dabire"
                                value={formData?.prenom}
                                onChange={(event) => setFormData({ ...formData, prenom: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="telephone" value="Tel" />
                            </div>
                            <TextInput
                                id="telephone"
                                placeholder="+226 70 xxxx"
                                value={formData?.telephone}
                                onChange={(event) => setFormData({ ...formData, telephone: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="email" value="Email" />
                            </div>
                            <TextInput
                                id="email"
                                placeholder="email@email.com"
                                value={formData?.email}
                                onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                                required
                            />

                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="age" value="Age" />
                            </div>
                            <TextInput
                                id="age"
                                placeholder="...."
                                type='number'
                                value={formData?.age}
                                onChange={(event) => setFormData({ ...formData, age: event.target.value })}
                                required
                            />

                        </div>


                        <div>
                            <form className="mx-auto">
                                <label for="sexe" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sexe</label>
                                <select

                                    value={formData?.sexe}
                                    onChange={(event) => setFormData({ ...formData, sexe: event.target.value })}
                                    id="sexe" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected>Choisir une option</option>
                                    <option value="Feminin">Feminin</option>
                                    <option value="Masculin">Masculin</option>

                                </select>
                            </form>

                        </div>

                        <div className="w-full">
                            <Button onClick={submiData}


                            >Enregistrer</Button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openModalAlert} size="md" onClose={() => setOpenModalAlert(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {infosMessage}
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => setOpenModalAlert(false)}>
                                {"Fermer"}
                            </Button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal show={openModaFile} size="md" onClose={() => setOpenModalFile(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <div class="flex items-center justify-center w-full">
                            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Cliquez pour Charger</span> ou faites glisser-déposer</p>
                                    <p class="text-xs text-gray-500 dark:text-gray-400">{formData?.fichierExcel ? formData?.fichierExcel?.name : "CSV ou Xlxs"}</p>
                                </div>
                                <input
                                    // value={formData?.fichierExcel || ''}
                                    onChange={(event) => setFormData({ ...formData, fichierExcel: event.target.files[0] })}
                                    id="dropzone-file" type="file" class="hidden" />
                            </label>
                        </div>
                        <div className="flex gap-4 mt-4">

                            <Button color="failure" onClick={() => setOpenModalFile(false)}>
                                {"Fermer"}
                            </Button>
                            <Button color="success" onClick={() => submiDataImport(false)}>
                                {"Enregistrer"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default AdminApprenants;
