import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { URLS, axiosGetWithToken, axiosPostWithToken, axiosPutWithToken } from '../../utils';

import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react';

function AdminApprenants() {
    const [listData, setListData] = useState([]); // Pour stocker les données de la BDD.
    const [currentPage, setCurrentPage] = useState(1); // Pour suivre la page actuelle
    const [searchTerm, setSearchTerm] = useState(''); // Pour stocker le terme de recherche

    const pageSize = 5; // Nombre d'éléments par page
    const [selectedBrand, setSelectedBrand] = useState('');

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };
    const loadData = async () => {
        try {
            const response = await axiosGetWithToken(URLS?.formations);
            console.log("Réponse du serveur : ", response)
            setListData(response)
        } catch (error) {
            console.error("Erreur lors du chargement des données :", error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    // Pagination
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = listData.filter(item =>
        item?.libelle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.description?.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(indexOfFirstItem, indexOfLastItem);
    // Change de page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const [openModalAlert, setOpenModalAlert] = useState(false);
    const [infosMessage, setInfosMessage] = useState('Infos');
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    function onCloseModal() {
        setOpenModal(false);
        setFormData({});
    }



    const submiData = async () => {
        try {
            let response;
            if (formData?.id) {
                console.log(formData);
                response = await axiosPutWithToken(`${URLS?.formations}/${formData?.id}`, formData);
            } else {

                response = await axiosPostWithToken(URLS?.formations, formData);

            }
            setInfosMessage(response?.message || "La formation a été enregistrée avec succès.");

        } catch (error) {
            console.error("Erreur lors de la soumission des données :", error);
            setInfosMessage(error?.response?.data?.message || "Erreur lors de la soumission des données");
        } finally {
            setOpenModal(false);
            setOpenModalAlert(true);
            setFormData({});
            loadData(); // Charger les données uniquement après soumission
        }
    };


    return (
        <section className="dark:bg-gray-900 py-3 sm:py-5">
            {/* Le reste du contenu */}


            <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">

                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <form className="flex items-center">
                            <label for="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <input

                                    type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button onClick={() => setOpenModal(true)} type="button" className="flex items-center justify-center text-white bg-blue-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                            <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                            </svg>
                            Nouveau apprenant
                        </button>
                        <div className="flex items-center space-x-3 w-full md:w-auto">


                            <div >
                                <select
                                    id="filterDropdown"
                                    className="z-10 block w-48 p-2 bg-white rounded-lg shadow dark:bg-gray-700"
                                    value={selectedBrand}
                                    onChange={handleBrandChange}
                                >
                                    <option value="">Choose brand</option>
                                    <option value="apple">Apple (56)</option>
                                    <option value="fitbit">Microsoft (16)</option>
                                    <option value="razor">Razor (49)</option>
                                    <option value="nikon">Nikon (12)</option>
                                    <option value="benq">BenQ (74)</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='flex'>

                <div className="mx-auto  items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">

                    <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">
                        <div className="mb-5">
                            <label htmlFor="base-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Libellé</label>
                            <input
                                value={formData?.libelle}
                                onChange={(event) => setFormData({ ...formData, libelle: event.target.value })}
                                type="text" id="base-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>


                            <textarea
                                value={formData?.description}
                                onChange={(event) => setFormData({ ...formData, description: event.target.value })} id="description" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
                        </div>
                        <button onClick={() => {
                            submiData()
                        }} className="m-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enregistrer</button>
                    </div>

                </div>

                <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 ">
                    <div className="bg-white">
                        <div className="mx-auto  items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">

                            <div >
                                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                                    {currentItems.map((item, index) => (
                                        <div key={index} className="border-t border-gray-200 pt-4">
                                            <dt className="font-medium text-gray-900">{item?.libelle}</dt>
                                            <dd className="mt-2 text-sm text-gray-500">{item?.description}</dd>


                                            <button type="button"
                                                onClick={() => {
                                                    setFormData(item)
                                                }}

                                                className="m-1 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Edit</button>

                                        </div>
                                    ))}
                                </dl>
                            </div>

                        </div>
                    </div>
                    {/* Pagination */}
                    <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Navigation de la table">
                        {/* Affichage de la pagination */}
                        <ul className="inline-flex items-stretch -space-x-px">
                            {/* Bouton précédent */}
                            <li>
                                <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <span className="sr-only">Précédent</span>
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        {/* Icône du bouton précédent */}
                                    </svg>
                                </a>
                            </li>
                            {/* Affichage des pages */}
                            {[...Array(Math.ceil(listData.length / pageSize)).keys()].map(number => (
                                <li key={number}>
                                    <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => paginate(number + 1)}>
                                        {number + 1}
                                    </a>
                                </li>
                            ))}
                            {/* Bouton suivant */}
                            <li>
                                <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <span className="sr-only">Suivant</span>
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        {/* Icône du bouton suivant */}
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>

            <Modal show={openModal} size="md" onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="nom" value="Nom" />
                            </div>
                            <TextInput
                                id="nom"
                                placeholder="COMPAORE"
                                value={formData?.npom}
                                onChange={(event) => setFormData({ ...formData, npom: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="prenom" value="Prénom" />
                            </div>
                            <TextInput
                                id="prenom"
                                placeholder="Dabire"
                                value={formData?.prenom}
                                onChange={(event) => setFormData({ ...formData, prenom: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="telephone" value="Tel" />
                            </div>
                            <TextInput
                                id="telephone"
                                placeholder="+226 70 xxxx"
                                value={formData?.telephone}
                                onChange={(event) => setFormData({ ...formData, telephone: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="email" value="Email" />
                            </div>
                            <TextInput
                                id="email"
                                placeholder="email@email.com"
                                value={formData?.email}
                                onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                                required
                            />

                        </div>

                        <div className="w-full">
                            <Button onClick={submiData}


                            >Enregistrer</Button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openModalAlert} size="md" onClose={() => setOpenModalAlert(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {infosMessage}
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => setOpenModalAlert(false)}>
                                {"Fermer"}
                            </Button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default AdminApprenants;
