import React from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';


function LayoutVisitor() {
    return (
        <div className="bg-white dark:bg-gray-900  w-full z-20 top-0 left-0">
            <div className="pt-20 bg-white">
                <Outlet />
            </div>
        </div>
    )
}

export default LayoutVisitor