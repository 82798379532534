import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { URLS, axiosGetWithToken, axiosPostWithToken, axiosPutWithToken, serverIMAGEURls,getDateFormatted,getDateFormat } from '../../utils';

function VisitorHome() {
    const [listQrCode, setListQrcode] = useState([]); // Pour stocker les données de la BDD.
    const [formationList, setFormationList] = useState([]);
    const formattedDate = getDateFormatted();

    const [formData, setFormData] = useState({
        date: formattedDate,
    });
    const loadData = async () => {
        try {
            const [response
                , response1
                //, response3
            ] = await Promise.all([
                axiosGetWithToken(URLS?.seancesduJours),
                axiosPostWithToken(URLS?.statData, formData)
                // axiosGetWithToken(URLS?.formations)
            ]);
            // console.log(response);
            setFormationList(response1);
            setListQrcode(response);

        } catch (error) {
            console.error("Erreur lors du chargement des données :", error);
        }
    }




    const handleResearch = async () => {
        try {
            const response = await axiosPostWithToken(URLS?.statData, formData)
            setFormationList(response);
        } catch (error) {

        }
    }


    useEffect(() => {
        loadData();
    }, []);



    return (
        <div className="bg-white">
            <div className="pt-6">


                <div className="mx-auto mt-6 my-2 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
                    {listQrCode && listQrCode.map((item, index) => (
                        <div key={index}>
                            <div className="rounded-lg flex m-2 flex-wrap border">
                                <img src={`${serverIMAGEURls}${item?.qrCodeSeanceDebut}`} alt={`${item?.titre}`} className="h-full w-1/2 object-cover object-center" />
                                <img src={`${serverIMAGEURls}${item?.qrCodeSeanceDebut}`} alt={`${item?.titre}`} className="h-full w-1/2 object-cover object-center" />

                            </div>
                            <p className='ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500'>{item?.titre}</p>

                        </div>

                    ))}

                </div>


                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Comment ça fonctionne ?</h1>
                    </div>


                    <div className="mt-4 lg:row-span-3 lg:mt-0">
                        <p className="text-xl tracking-tight text-gray-900 lg:text-2xl md:text-xs">{getDateFormat(formData?.date)}</p>


                        <div className="mt-6">
                            <div className="flex items-center">
                                <div className="flex items-center">

                                    <svg className="text-gray-900 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                    </svg>
                                    <svg className="text-gray-900 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                    </svg>
                                    <svg className="text-gray-900 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                    </svg>


                                </div>
                                {/* <a href="#" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">Nombre de présence : 118 </a> */}
                            </div>
                            <div className="flex items-center  my-3">


                                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" class="px-6 py-3">
                                                    Formation
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                    Localité
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formationList && formationList.map((formation, idx) => (
                                                <tr key={idx} class="bg-white border dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {formation?.formation}
                                                    </th>
                                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {formation?.localite}
                                                    </th>
                                                    <th class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        <a href="#" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500"> {formation?.nbrePresence} </a>

                                                    </th>

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                        <div className="mt-10">

                            <div className="mt-10">
                                <div>
                                    <label for="date" class="block text-sm font-medium leading-6 text-gray-900">Date</label>
                                    <div class="relative mt-2 rounded-md shadow-sm">

                                        <input type="date"
                                            value={formData?.date}
                                            onChange={(event) => setFormData({ ...formData, date: event.target.value })}

                                            name="date" id="date" class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 m-2 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />

                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    {/* <h3 className="text-sm font-medium text-gray-900">Size</h3> */}
                                    <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">Jours</a>
                                </div>
                                {/* 
                                <fieldset className="mt-4">
                                    <legend className="sr-only">Choose a size</legend>
                                    <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                                        <label className="group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium  hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6 cursor-not-allowed bg-gray-50 text-gray-200">
                                            <input type="radio" name="size-choice" value="Dimanche" disabled className="sr-only" aria-labelledby="size-choice-0-label" />
                                            <span id="size-choice-0-label">Dimanche</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200">
                                                <svg className="absolute inset-0 h-full w-full stroke-2 text-gray-200" viewBox="0 0 100 100" preserveAspectRatio="none" stroke="currentColor">
                                                    <line x1="0" y1="100" x2="100" y2="0" vector-effect="non-scaling-stroke" />
                                                </svg>
                                            </span>
                                        </label>
                                        {joursSemaine.map((jour, index) => (
                                            <label key={index} className="group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium  hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6 cursor-pointer bg-white text-gray-900 shadow-sm">
                                                <input type="radio" name="size-choice" value={jour} className="sr-only" aria-labelledby={`size-choice-${index + 1}-label`} />
                                                <span id={`size-choice-${index + 1}-label`}>{jour}</span>
                                                <span className="pointer-events-none absolute -inset-px rounded-md" aria-hidden="true"></span>
                                            </label>
                                        ))}

                                    </div>
                                </fieldset> */}
                            </div>

                            <button
                                onClick={() => { handleResearch() }}
                                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Rechercher</button>
                        </div>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                        <div>
                            <h3 className="sr-only">Description</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">Tout d'abord, il faut être apprenant(e) d'une de nos formations et s'inscrire depuis l'application mobile. Une fois cela effectué, vous devez scanner le QR Code avant le début de chaque séance et après pour signaler votre présence. Votre présence n'est confirmée que si les deux scans sont effectués, c'est-à-dire que vous avez effectué le scan avant et après chaque cours, ou bien dans les 20 minutes suivant le début de la séance et les 20 minutes précédant la fin.</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="text-sm font-medium text-gray-900">Consignes</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    <li className="text-gray-400"><span className="text-gray-600">Inscription préalable à la formation.</span></li>
                                    <li className="text-gray-400"><span className="text-gray-600">Respect des horaires de début et de fin de la formation.</span></li>
                                    <li className="text-gray-400"><span className="text-gray-600">Participation active aux activités prévues durant la formation.</span></li>
                                    <li className="text-gray-400"><span className="text-gray-600">Respect du règlement intérieur de la formation.</span></li>
                                    <li className="text-gray-400"><span className="text-gray-600">Signalement de toute absence ou retard au coordonateur de la formation.</span></li>
                                    <li className="text-gray-400"><span className="text-gray-600">Remplir et soumettre tout formulaire ou document requis pour l'attestation.</span></li>
                                    <li className="text-gray-400"><span className="text-gray-600">Se conformer aux exigences spécifiques de présence établies par l'organisateur de la formation.</span></li>

                                </ul>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h2 className="text-sm font-medium text-gray-900">Details</h2>

                            <div className="mt-4 space-y-6">
                                <p className="text-sm text-gray-600">Pour tous besoins, veuillez vous adresser au coordinateur de votre centre de formation ou écrire à l'adresse essitechcontact@essitechgroup.com.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default VisitorHome