import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LayoutAdmin } from '../../layout';
import {
  AdminSeances, AdminUsers, AdminApprenants, AdminFormation,AdminPresence

} from '../../pages/admin';
import { Error404 } from '../../pages';

function AdminRoutes() {
  return (
    <Routes >

      <Route element={<LayoutAdmin />}>
        <Route index element={<AdminApprenants />} />
        <Route path='panel-administration'>

          <Route path='users' element={<AdminUsers />} />
          <Route path='seance' element={<AdminSeances />} />
          <Route path='apprenants' element={<AdminApprenants />} />
          <Route path='activites' element={<AdminFormation />} />
          <Route path='presence' element={<AdminPresence />} />
        </Route>
        <Route path='/*' element={<Error404 />} />
      </Route>
    </Routes >
  )
}

export default AdminRoutes