import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AdminAuthGuard, AdminRoutes, VisitorRoutes } from './routes';


function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route
          path='essitech/*'
          element={
            <AdminAuthGuard>
              <AdminRoutes />
            </AdminAuthGuard>
          }

        />

        <Route
          path='*'
          element={
            <VisitorRoutes />
          }

        />
      </Routes>
    </BrowserRouter>
  );

}

export default App;