import React from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';


function LayoutAdmin() {

  const location = useLocation();
  const navigate = useNavigate();


  return (
    <>

      <div className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0">

        <header>
          <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 border-b-2">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
              <Link to="/" className="flex items-center">
                <img src={require('../images/logo.svg').default} className="mr-3 h-24 sm:h-16" alt="Essitech Logo" />
                <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white"></span>
              </Link>


              <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <Link to="/essitech/panel-administration/apprenants" className={`block p-1 rounded-lg pr-4 pl-3  border-b border-gray-100 ${location.pathname === '/essitech/panel-administration/apprenants' ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'} `}>Les apprenants</Link>
                  </li>
                  <li>
                    <Link to="/essitech/panel-administration/presence" className={`block p-1 rounded-lg pr-4 pl-3  border-b border-gray-100 ${location.pathname === '/essitech/panel-administration/presence' ? 'bg-blue-700 text-white' : 'hover:bg-blue-500 hover:text-white'} `}>Les présences</Link>
                  </li>
                  <li>
                    <Link to="/essitech/panel-administration/seance" className={`block p-1 rounded-lg pr-4 pl-3  border-b border-gray-100 ${location.pathname === '/essitech/panel-administration/seance' ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'} `}>Les séances</Link>
                  </li>
                  <li>
                    <Link to="/essitech/panel-administration/activites" className={`block p-1 rounded-lg pr-4 pl-3  border-b border-gray-100 ${location.pathname === '/essitech/panel-administration/activites' ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'} `}>Les activités / Formations</Link>
                  </li>
                </ul>
              </div>


            </div>
          </nav>
        </header>

      </div>

      <div className="pt-20 bg-white">
        <Outlet />
      </div>
    </>
  )
}

export default LayoutAdmin