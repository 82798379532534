import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LayoutVisitor } from '../../layout';
import { VisitorContactus, VisitorHome } from '../../pages/visitor';
import { Error404 } from '../../pages';


function VisitorRoutes() {
    return (
        <Routes >

            <Route element={<LayoutVisitor />}>
                <Route index element={<VisitorHome />} />
                <Route path='home' element={<VisitorHome />} />
                <Route path='contact' element={<VisitorContactus />} />
            </Route>
            <Route path='/*' element={<Error404 />} />
        </Routes >
    )
}

export default VisitorRoutes