import React from 'react';
import { Navigate } from "react-router-dom";

function AdminAuthGuard({ children }) {
  // Votre logique de vérification d'authentification ici
  const isAuthenticated = true; // Mettez votre logique d'authentification ici

  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/essitechsas/panel-administration/authentification" />;
  }
}

export default AdminAuthGuard;