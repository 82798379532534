import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { URLS, axiosGetWithToken, axiosPostWithToken, axiosPutWithToken } from '../../utils';

import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react';




function AdminSeances() {
    const [listData, setListData] = useState([]); // Pour stocker les données de la BDD.
    const [listDataDFormation, setListDataFormation] = useState([]); // Pour stocker les données de la BDD.
    const [listDataLocalite, setListDataLocalite] = useState([]); // Pour stocker les données de la BDD.
    const [currentPage, setCurrentPage] = useState(1); // Pour suivre la page actuelle
    const [searchTerm, setSearchTerm] = useState(''); // Pour stocker le terme de recherche

    const pageSize = 10; // Nombre d'éléments par page
    const [selectedBrand, setSelectedBrand] = useState('');

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };
    const loadData = async () => {
        try {
            const [response, response1,
                
               response3
            ] = await Promise.all([
                axiosGetWithToken(URLS?.seances),
                axiosGetWithToken(URLS?.localites),
               axiosGetWithToken(URLS?.formations)
            ]);
            setListData(response?.seances);
            setListDataLocalite(response1?.localites);
            setListDataFormation(response3);
        } catch (error) {
            console.error("Erreur lors du chargement des données :", error);
        }
    }




    useEffect(() => {
        loadData();
    }, []);

    // Pagination
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;


    const filterFields = ["titre", "date"];

    const currentItems = listData
        .filter(item => {
            return filterFields.some(field =>
                item[field]?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
        .slice(indexOfFirstItem, indexOfLastItem);
    // Change de page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const [openModalAlert, setOpenModalAlert] = useState(false);
    const [infosMessage, setInfosMessage] = useState('Infos');
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({

    });


    const [openModaFile, setOpenModalFile] = useState(false);

    function onCloseModal() {
        setOpenModal(false);
        setFormData({});
    }



    const submiData = async () => {
        try {
            let response;
            if (formData?.id) {
                console.log(formData);
                response = await axiosPutWithToken(`${URLS?.seances}/${formData?.id}`, formData);
            } else {

                response = await axiosPostWithToken(URLS?.seances, formData);

            }
            setInfosMessage(response?.message || "Le participant a été enregistré avec succès.");

        } catch (error) {
            console.error("Erreur lors de la soumission des données :", error);
            setInfosMessage(error?.response?.data?.message || "Erreur lors de la soumission des données");
        } finally {
            console.log(formData);
            setOpenModal(false);
            setOpenModalAlert(true);
            loadData(); // Charger les données uniquement après soumission
            setFormData({});
        }
    };



    const submiDataImport = async () => {
        try {

            let response = await axiosPostWithToken(URLS?.importseance, formData);
            setInfosMessage(response?.message || "Les  séances ont été enregistrés avec succès.");

        } catch (error) {
            console.error("Erreur lors de la soumission des données :", error);
            setInfosMessage(error?.response?.data?.message || "Erreur lors de la soumission des données");
        } finally {
            setOpenModalFile(false);
            setOpenModalAlert(true);
            loadData(); // Charger les données uniquement après soumission
            setFormData({});
        }
    };



    return (
        <section className="dark:bg-gray-900 py-3 sm:py-5">
            {/* Le reste du contenu */}


            <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">

                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <form className="flex items-center">
                            <label for="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <input type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button onClick={() => setOpenModal(true)} type="button" className="flex items-center justify-center text-white bg-blue-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                            <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                            </svg>
                            Nouvelle seance
                        </button>
                        <div className="flex items-center space-x-3 w-full md:w-auto">


                            <div >
                                <select
                                    id="filterDropdown"
                                    className="z-10 block w-48 p-2 bg-white rounded-lg shadow dark:bg-gray-700"
                                    value={selectedBrand}
                                    onChange={handleBrandChange}
                                >
                                    <option value="">Choose brand</option>
                                    <option value="apple">Apple (56)</option>
                                    <option value="fitbit">Microsoft (16)</option>
                                    <option value="razor">Razor (49)</option>
                                    <option value="nikon">Nikon (12)</option>
                                    <option value="benq">BenQ (74)</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">
                {/* Contenu de la table */}
                <div className="flex bg-blue-500 text-white flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                    <div className="flex items-center flex-1 space-x-4">
                        <h5>
                            <span >Total :</span>
                            <span className="dark:text-white">{listData?.length}</span>
                        </h5>

                    </div>
                    <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                        <button

                            onClick={() => { setOpenModalFile(true) }}
                            type="button" className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <svg className="h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12" />
                            </svg>

                            Import de la liste
                        </button>

                        <button type="button" className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                            </svg>
                            Export
                        </button>
                    </div>
                </div>






                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                    Titre
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Horaire
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                    Formation
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Description
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index} className="border-b border-gray-200 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                                        {item?.titre ?? "--"}
                                    </th>
                                    <td className="px-6 py-4">
                                        {item?.date ?? "--"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item?.heureDebut ?? "--"} _  {item?.heureDebut ?? "--"}
                                    </td>
                                    <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                                        {item?.titre ?? "--"}
                                    </td>
                                    <td className="px-6 py-4">
                                        {item?.description ?? "--"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Navigation de la table">
                    {/* Affichage de la pagination */}
                    <ul className="inline-flex items-stretch -space-x-px">
                        {/* Bouton précédent */}
                        <li>
                            <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span className="sr-only">Précédent</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    {/* Icône du bouton précédent */}
                                </svg>
                            </a>
                        </li>
                        {/* Affichage des pages */}
                        {[...Array(Math.ceil(listData.length / pageSize)).keys()].map(number => (
                            <li key={number}>
                                <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => paginate(number + 1)}>
                                    {number + 1}
                                </a>
                            </li>
                        ))}
                        {/* Bouton suivant */}
                        <li>
                            <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span className="sr-only">Suivant</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    {/* Icône du bouton suivant */}
                                </svg>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>


            <Modal show={openModal} size="md" onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="titre" value="Titre" />
                            </div>
                            <TextInput
                                id="titre"
                                placeholder="COMPAORE"
                                value={formData?.titre}
                                onChange={(event) => setFormData({ ...formData, titre: event.target.value })}
                                required
                            />

                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="date" value="Date" />
                            </div>
                            <TextInput
                                id="date"
                                type='date'
                                value={formData?.date}
                                onChange={(event) => setFormData({ ...formData, date: event.target.value })}
                                required
                            />

                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="heureDebut" value="Heure de debut"/>
                            </div>
                            <TextInput
                                id="heureDebut"
                                type='datetime-local'
                                value={formData?.heureDebut}
                                onChange={(event) => setFormData({ ...formData, heureDebut: event.target.value })}
                                required
                            />

                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="heureFin" value="Heure de Fin"/>
                            </div>
                            <TextInput
                                id="heureFin"
                                type='datetime-local'
                                value={formData?.heureFin}
                                onChange={(event) => setFormData({ ...formData, heureFin: event.target.value })}
                                required
                            />

                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="formationId" value="Formation" />
                            </div>
                            <select
                                value={formData?.formationId}
                                onChange={(event) => setFormData({ ...formData, formationId: event.target.value })}
                                id="formationId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Selectionner </option>
                                {listDataDFormation && listDataDFormation.map((item, index) => (<option key={index} value={item?.id}>{item?.libelle}</option>))}
                            </select>

                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="localiteId" value="Localité" />
                            </div>
                            <select
                                value={formData?.localiteId}
                                onChange={(event) => setFormData({ ...formData, localiteId: event.target.value })}
                                id="localiteId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Selectionner</option>
                                <option selected>Choose </option>
                                {listDataLocalite && listDataLocalite.map((item, index) => (<option key={index} value={item?.id}>{item?.ville}</option>))}
                            </select>

                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="description" value="Description" />
                            </div>
                            <textarea
                                rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                id="description"
                                placeholder="Desc"
                                value={formData?.description}
                                onChange={(event) => setFormData({ ...formData, description: event.target.value })}
                                required
                            >
                            </textarea>


                        </div>

                        <div className="w-full">
                            <Button onClick={submiData}


                            >Enregistrer</Button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>



            <Modal show={openModaFile} size="md" onClose={() => setOpenModalFile(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <div class="flex items-center justify-center w-full">
                            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Cliquez pour Charger</span> ou faites glisser-déposer</p>
                                    <p class="text-xs text-gray-500 dark:text-gray-400">{formData?.fichierExcel ? formData?.fichierExcel?.name : "CSV ou Xlxs"}</p>
                                </div>
                                <input
                                    // value={formData?.fichierExcel || ''}
                                    onChange={(event) => setFormData({ ...formData, fichierExcel: event.target.files[0] })}
                                    id="dropzone-file" type="file" class="hidden" />
                            </label>
                        </div>
                        <div className="flex gap-4 mt-4">

                            <Button color="failure" onClick={() => setOpenModalFile(false)}>
                                {"Fermer"}
                            </Button>
                            <Button color="success" onClick={() => submiDataImport(false)}>
                                {"Enregistrer"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openModalAlert} size="md" onClose={() => setOpenModalAlert(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {infosMessage}
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => setOpenModalAlert(false)}>
                                {"Fermer"}
                            </Button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default AdminSeances;
