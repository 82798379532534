import axios from 'axios';

// Fonction utilitaire pour récupérer le token depuis le localStorage
const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem('token');
    return token;
};


const serverURls = "https://epresencebackend.essitechgroup.com/"
const serverIMAGEURls = "https://epresencebackend.essitechgroup.com/qrcode/"

// Fonction pour envoyer une requête GET avec Axios et le token récupéré du localStorage
const axiosGetWithToken = async (url) => {
    const token = getTokenFromLocalStorage();
    try {
        const response = await axios.get(`${serverURls}${url}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la requête GET :', error);
        throw error;
    }
};

// Fonction pour envoyer une requête POST avec Axios et le token récupéré du localStorage
const axiosPostWithToken = async (url, data) => {
    const token = getTokenFromLocalStorage();
    try {
        const response = await axios.post(`${serverURls}${url}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'multipart/form-data', // Spécifier les types MIME acceptés
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la requête POST :', error);
        throw error;
    }
};

// Fonction pour envoyer une requête PUT avec Axios et le token récupéré du localStorage
const axiosPutWithToken = async (url, data) => {
    const token = getTokenFromLocalStorage();
    try {
        const response = await axios.put(`${serverURls}${url}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json, multipart/form-data' // Spécifier les types MIME acceptés
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la requête PUT :', error);
        throw error;
    }
};

// Fonction pour envoyer une requête DELETE avec Axios et le token récupéré du localStorage
const axiosDeleteWithToken = async (url) => {
    const token = getTokenFromLocalStorage();
    try {
        const response = await axios.delete(`${serverURls}${url}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la requête DELETE :', error);
        throw error;
    }
};


function getDateFormatted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Mois (ajoute un zéro devant si nécessaire pour obtenir deux chiffres)
    const day = String(today.getDate()).padStart(2, '0'); // Jour (ajoute un zéro devant si nécessaire pour obtenir deux chiffres)
    return `${year}-${month}-${day}`;
}


function getDateFormat(dateString) {
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthsOfYear = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    const dateParts = dateString.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Mois est 0-indexé dans Date object
    const day = parseInt(dateParts[2]);

    const dateObject = new Date(year, month, day);
    const dayOfWeek = daysOfWeek[dateObject.getDay()];
    const dayOfMonth = dateObject.getDate();
    const monthName = monthsOfYear[dateObject.getMonth()];

    return `${dayOfWeek} ${dayOfMonth} ${monthName} ${year}`;
}

const URLS = {
    participants: 'participants',
    formations: 'formations',
    localites: 'localites',
    seances: 'seances',
    import: 'participants/import',
    importseance: 'seances/import',
    seancesduJours: 'seances/current-date',
    presence: 'presences/participants',
    statData: 'presences/nombre/formation-localite',


}


export { axiosGetWithToken, axiosPostWithToken, axiosPutWithToken, axiosDeleteWithToken, URLS, serverIMAGEURls, getDateFormatted, getDateFormat };

